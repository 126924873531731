import React, { ComponentProps, FC } from 'react';
import FavoriteButton, { ActiveHeart, FavoriteButtonProps, InactiveHeart } from './FavoriteButton';
import styled, { css } from 'styled-components';

const FavoriteIconButton: FC<
    React.PropsWithChildren<Omit<FavoriteButtonProps, 'ActiveButtonComponent' | 'InactiveButtonComponent' | 'Spinner'>>
> = props => (
    <FavoriteButton
        {...props}
        InactiveButtonComponent={InactiveFavoriteIconButton}
        ActiveButtonComponent={ActiveFavoriteIconButton}
        Spinner={Spinner}
    />
);

const InactiveFavoriteIconButton: FC<
    React.PropsWithChildren<ComponentProps<Required<FavoriteButtonProps>['InactiveButtonComponent']>>
> = props => (
    <BaseButton
        {...props}
        onClick={e => {
            e.preventDefault();
            props.onClick?.(e);
        }}
    >
        <InactiveHeart />
    </BaseButton>
);
const ActiveFavoriteIconButton: FC<
    React.PropsWithChildren<ComponentProps<Required<FavoriteButtonProps>['ActiveButtonComponent']>>
> = props => (
    <BaseButton
        {...props}
        onClick={e => {
            e.preventDefault();
            props.onClick?.(e);
        }}
    >
        <ActiveHeart />
    </BaseButton>
);

const Spinner = () => <InactiveFavoriteIconButton disabled onClick={e => e.preventDefault()} />;

const BaseButton = styled.button<{ disabled?: boolean }>`
    appearance: none;
    margin: 0;
    padding: 0;

    svg {
        margin: 0;
    }

    ${({ disabled }) =>
        disabled &&
        css`
            opacity: 0.3;
            cursor: progress;
        `}
`;

export default FavoriteIconButton;
