import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';

interface ReviewTermProps {
    average: number;
}

// Note: this is currently the only way to generate different messages for the reviewTerm
// because we use babel-plugin-react-intl and this plugin needs the id to be static
// (so no string interpolation id={`detail.reviewStats.reviewTerm.variabel-name`} or id={variable-name} support).
const FormattedReviewTerm: FC<React.PropsWithChildren<ReviewTermProps>> = ({ average }) => {
    if (average === 10) {
        return <FormattedMessage defaultMessage="Uitmuntend" />;
    }
    if (average >= 9.5) {
        return <FormattedMessage defaultMessage="Voortreffelijk" />;
    }
    if (average >= 9) {
        return <FormattedMessage defaultMessage="Fantastisch" />;
    }
    if (average >= 8.6) {
        return <FormattedMessage defaultMessage="Heerlijk" />;
    }
    if (average >= 8) {
        return <FormattedMessage defaultMessage="Erg goed" />;
    }
    if (average >= 7) {
        return <FormattedMessage defaultMessage="Goed" />;
    }
    return <FormattedMessage defaultMessage="Beoordeling" />;
};

export default FormattedReviewTerm;
