import isAfter from 'date-fns/isAfter';
import isSameDay from 'date-fns/isSameDay';
import { QueryParamConfig, encodeString, decodeString } from 'use-query-params';
import isDate from './isDate';

/**
 * The DataParam will decode succesfully in case of the following
 * - Value is a string
 * - Value is a date in string format or an empty string meaning no date is selected
 * - Date is today or in the future
 *
 */
const DateParam: QueryParamConfig<string | undefined, string | undefined> = {
    encode: encodeString,
    decode: val => {
        const decodedVal = decodeString(val);

        if (typeof decodedVal !== 'string' || decodedVal === '') {
            return decodedVal ?? undefined;
        }
        const today = new Date();
        const dateVal = new Date(decodedVal);
        if (isDate(dateVal) && (isSameDay(today, dateVal) || isAfter(dateVal, today))) {
            return decodedVal;
        }

        return undefined;
    },
};

export default DateParam;
