import styled from 'styled-components';
import React, { FC } from 'react';
import { FormattedNumber } from 'react-intl';
import FormattedReviewTerm from './FormattedReviewTerm';
import { getAssignmentColor } from '@oberoninternal/travelbase-ds/constants/theme';
import Body from '@oberoninternal/travelbase-ds/components/primitive/Body';
import Star from '@oberoninternal/travelbase-ds/svg/Star.svg';

interface Props {
    value: number;
}

const Rating: FC<React.PropsWithChildren<Props>> = ({ value }) => (
    <Container>
        <Star />
        <Body variant="small">
            <FormattedNumber value={value} /> <FormattedReviewTerm average={value} />
        </Body>
    </Container>
);

export default Rating;

const Container = styled.div`
    display: flex;
    align-items: center;
    color: ${({ theme }) =>
        theme.colorAssignments.rating
            ? getAssignmentColor(theme, theme.colorAssignments.rating)
            : theme.colors.secondary['30']};
    > p {
        margin-left: ${({ theme }) => theme.spacing['20_Tiny']};
        font-weight: 700;
    }
    > svg {
        margin-top: -${({ theme }) => theme.spacing['10_Micro']};
        width: 1.6rem;
    }
    margin-bottom: 0;
`;
